<template>
  <a-spin :spinning="loading">
    <div v-if="isProductPresent">
      <div
        v-for="(product, index) in data"
        :key="index"
      >
        <a-divider v-if="index > 0" />

        <a-row>
          <a-col :span="8">
            <a-descriptions
              :column="1"
              class="iotplt-descriptions iotplt-descriptions-max-6"
            >
              <a-descriptions-item :label="`历史套餐 ${index+1}`">
                {{ product.product_name }}
              </a-descriptions-item>
              <a-descriptions-item label="用户业务模式">
                {{ product.user_business_type }}
              </a-descriptions-item>
              <a-descriptions-item label="订购时间">
                {{ product.subscribed_at }}
              </a-descriptions-item>
              <a-descriptions-item label="套餐周期数">
                {{ product.cycles }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="8">
            <a-descriptions
              :column="1"
              class="iotplt-descriptions iotplt-descriptions-max-8"
            >
              <a-descriptions-item label="套餐服务开始时间">
                {{ product.effective_time }}
              </a-descriptions-item>
              <a-descriptions-item label="套餐服务结束时间">
                {{ product.expiration_time }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="8">
            <a-descriptions
              :column="1"
              class="iotplt-descriptions iotplt-descriptions-max-6"
            >
              <a-descriptions-item label="周期序列">
                <a-select
                  style="width: 100px"
                  :value="product.sim_card_product_cycle_usage.cycle"
                  @change="(cycle) => fetchSimCardProductCycleUsageData(cycle, product.id, index)"
                >
                  <a-select-option
                    v-for="option in product.cycleOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </a-select-option>
                </a-select>
              </a-descriptions-item>

              <a-descriptions-item label="周期容量">
                {{ product.sim_card_product_cycle_usage.cycle_total }}
              </a-descriptions-item>
              <a-descriptions-item label="周期已用量">
                {{ product.sim_card_product_cycle_usage.cycle_usage }}
              </a-descriptions-item>
              <a-descriptions-item label="周期剩余量">
                {{ product.sim_card_product_cycle_usage.cycle_left }}
              </a-descriptions-item>
              <a-descriptions-item label="容量使用进度">
                {{ product.sim_card_product_cycle_usage.use_progress }}
              </a-descriptions-item>
              <a-descriptions-item label="周期语音时长">
                {{ product.sim_card_product_cycle_usage.voice_capacity }}
              </a-descriptions-item>
              <a-descriptions-item label="周期已用时长">
                {{ product.sim_card_product_cycle_usage.voice_usage }}
              </a-descriptions-item>
              <a-descriptions-item label="周期剩余时长">
                {{ product.sim_card_product_cycle_usage.voice_left }}
              </a-descriptions-item>
              <a-descriptions-item label="周期开始时间">
                {{ product.sim_card_product_cycle_usage.cycle_begin_time }}
              </a-descriptions-item>
              <a-descriptions-item label="周期结束时间">
                {{ product.sim_card_product_cycle_usage.cycle_end_time }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-empty v-else />
  </a-spin>
</template>

<script>
import { findSimCardHistoryPackages, findSimCardProductCycleUsage } from '@/api/sim_card'

export default {
  name: 'ShowSimCardHistoryPackages',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: []
    }
  },
  computed: {
    isProductPresent() {
      return this.data.length > 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchSimCardProductCycleUsageData(cycle, productId, index) {
      findSimCardProductCycleUsage(this.id, { sim_cards_product_id: productId, cycle: cycle }).then((res) => {
        // 修改对应的周期数据
        this.$lodash.set(this.data, `[${index}].sim_card_product_cycle_usage`, { ...res.data })
      })
    },

    // 组装周期选项数据
    assembleCycleOptions(productInfo = {}) {
      const cycleOptions = []
      const totalCycle = productInfo.cycles

      for (let i = 1; i <= productInfo.sim_card_product_cycle_usage.cycle; i++) {
        cycleOptions.push({ label: `${i}/${totalCycle}`, value: i })
      }

      return cycleOptions
    },

    fetchData() {
      this.loading = true
      const vm = this
      findSimCardHistoryPackages(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.data = this.$lodash.map(this.data, function(productInfo) {
            return {
              ...productInfo,
              cycleOptions: vm.assembleCycleOptions(productInfo)
            }
          })
        }
        this.loading = false
      })
    }
  }
}
</script>
